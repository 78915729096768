<template>
  <page-view>
    <div :class="screenWidth >= 1680 ? 'page_wrapper' : ''">
      <div class="order_submit_wrapper">
        <a-steps class="steps" :current="currentTab">
          <a-step title="填写工单" />
          <a-step title="确认信息" />
          <a-step title="完成" />
        </a-steps>
        <div class="action_wrapper" v-if="currentTab === 0">
          <div class="action_item">
            <span class="label">问题类型：</span>
            <a-radio-group
              v-model="radio"
              class="trag_group"
              @change="selectRepairType"
            >
              <a-radio
                class="radioStyle"
                v-for="item in repair_type"
                :value="item.id"
                :key="item.id"
              >
                {{ item.type }}</a-radio
              >
            </a-radio-group>
          </div>

          <div class="action_item">
            <span class="label">工单标题：</span>
            <a-input
              placeholder="工单标题"
              v-model.trim="problemObj.title"
              class="input"
            />
          </div>

          <div class="action_item" v-if="[1, 2].includes(radio)">
            <span class="label">快递单号：</span>
            <a-input
              placeholder="多个运单号用逗号隔开"
              v-model.trim="problemObj.waybillNo"
              class="input"
            />
          </div>

          <div class="action_item">
            <span class="label">问题描述：</span>
            <a-textarea
              style=" width: 400px; height: 100px"
              :placeholder="submitInputPlaceholder"
              :autoSize="{ minRows: 5 }"
              v-model.trim="submitInputContent"
              class="resize_none"
            />
          </div>
          <div
            v-if="[1, 3].includes(radio)"
            v-on:paste="handlePaste"
            class="action_item"
          >
            <span class="label">上传图片：</span>
            <div>
              <!-- 上 -->
              <div style="display:flex">
                <!-- 预览 -->
                <div
                  style="position:relative"
                  @mouseenter="handleMouseEnter(item)"
                  @mouseleave="handleMouseLeave(item)"
                  v-for="(item, index) in imageArr"
                  :key="index"
                >
                  <img
                    title="点击预览"
                    :src="item.url"
                    class="submit_image"
                    preview
                  />

                  <div
                    class="edit_tip"
                    v-show="item.show"
                    @click="handleRemove(item)"
                  >
                    删除
                  </div>
                </div>

                <!-- 粘贴图片 -->
                <div
                  class="paste_box"
                  v-show="imageArr.length < 5"
                  @click="
                    () => {
                      clickCopyAera = true;
                    }
                  "
                >
                  <span :class="clickCopyAera ? 'active' : ''">CTRL+V </span>
                  <span :class="clickCopyAera ? 'active' : ''">粘贴图片</span>
                </div>
              </div>

              <!-- 下 -->
              <div>
                <div v-show="imageArr.length < 5">
                  截图成功后，点击上方虚线框内粘贴区域，按下Ctrl+V即可；
                </div>
                <div v-show="imageArr.length < 5">
                  点击此处
                  <a-upload
                    name="avatar"
                    list-type="picture"
                    class="avatar-uploader"
                    :show-upload-list="false"
                    :customRequest="handleChange"
                    accept="image/gif, image/png, image/jpeg, image/webp"
                  >
                    <span style="color:#1890ff; cursor: pointer;"
                      >上传图片</span
                    >
                  </a-upload>
                  ，最多可上传5张图片。
                </div>
                <div style="color: red">
                  *必填！需提供店铺申诉页面的截图证明
                </div>
              </div>
            </div>
          </div>

          <div class="action_item">
            <span class="label">
              {{ radio === 1 ? "微信号" : "联系人" }}
              ：
            </span>
            <a-input
              :placeholder="radio === 1 ? '方便工作人员给您发底单' : '联系人'"
              v-model.trim="problemObj.username"
              class="input"
            />
          </div>

          <div class="action_item">
            <span class="label">手机号码：</span>
            <a-input
              placeholder="手机号码"
              v-model.trim="problemObj.mobile"
              class="input"
            />
          </div>

          <div class="btn">
            <a-button @click="nextStep" type="primary">下一步</a-button>
          </div>
        </div>
        <div class="action_wrapper" v-if="currentTab === 1">
          <div class="action_item">
            <span class="label">问题类型：</span>
            <span>{{ problemObj.typeName }}</span>
          </div>

          <div class="action_item">
            <span class="label">工单标题：</span>
            <span>{{ problemObj.title }}</span>
          </div>

          <div class="action_item" v-if="[1, 2].includes(radio)">
            <span class="label">快递单号：</span>
            <span>{{ problemObj.waybillNo }}</span>
          </div>

          <div class="action_item">
            <span class="label">问题描述：</span>
            <span class="input">{{ submitInputContent }}</span>
          </div>
          <div v-if="[1, 3].includes(radio)" class="action_item">
            <span class="label">上传图片：</span>
            <div style="display:flex">
              <!-- 预览 -->
              <div
                style="position:relative"
                @mouseenter="handleMouseEnter(item)"
                @mouseleave="handleMouseLeave(item)"
                v-for="(item, index) in imageArr"
                :key="index"
              >
                <img
                  title="点击预览"
                  :src="item.url"
                  class="submit_image"
                  preview
                />

                <div
                  class="edit_tip"
                  v-show="item.show"
                  @click="handleRemove(item)"
                >
                  删除
                </div>
              </div>
            </div>
          </div>

          <div class="action_item">
            <span class="label">
              {{ radio === 1 ? "微信号" : "联系人" }}
              ：
            </span>
            <span>{{ problemObj.username }}</span>
          </div>

          <div class="action_item">
            <span class="label">手机号码：</span>
            <span>{{ problemObj.mobile }}</span>
          </div>

          <div class="btn">
            <a-button @click="prevStep" class="prve_step">上一步</a-button>
            <a-button @click="submitRepair" type="primary" :loading="loading"
              >提交</a-button
            >
          </div>
        </div>
        <div class="action_wrapper" v-if="currentTab === 2">
          <a-result
            title="操作成功"
            sub-title="请耐心等待结果，详情可见-我的工单"
          >
            <template #icon>
              <a-icon
                type="check-circle"
                theme="filled"
                :style="{ color: '#50c419' }"
              />
            </template>
          </a-result>
          <div class="last_step_btn">
            <a-button type="primary" @click="submitMore">再次申请 </a-button>
            <a-button
              style="margin-left:16px"
              @click="
                () => {
                  $router.push({ name: 'repairmyOrder' });
                }
              "
              >查看详情
            </a-button>
          </div>
        </div>
      </div>
      <div
        class="problem_wrapper"
        :class="screenWidth >= 1680 ? 'right1' : 'right2'"
      >
        <div class="problem_title">
          常见问题工单
        </div>

        <div class="common_problem">
          <div
            class="common_problem_item"
            v-for="(item, index) in problemList"
            :key="item.index"
          >
            <div class="item_question" @click="handleShowInfo(item, index)">
              <div
                class="circle"
                :class="activeInfo === index ? 'circle_active' : ''"
              ></div>
              <div
                class="item_title"
                :class="activeInfo === index ? 'active' : ''"
              >
                {{ item.question }}
              </div>
            </div>

            <div
              class="item_answer"
              v-show="activeInfo === index"
              v-html="item.answer"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </page-view>
</template>

<script>
import { PageView } from "@/layouts";
import { filePreUpload, uploadOSS, orderDetailList, sipUser } from "@/api";
import { commList, problemInsert } from "@/self";
import { uuid } from "@/utils/util";
const commonDescObj = {
  1: "输入问题描述，请发下申请底单的用途",
  2: "输入问题描述",
  3: "bug或优化建议"
};
export default {
  components: {
    PageView
  },
  data() {
    return {
      loading: false,
      /** 步骤1填写工单 2确认信息 3完成 */
      currentTab: 0,
      activeInfo: "",
      submitInputContent: "",
      submitInputPlaceholder: commonDescObj[1],
      problemList: [],
      /** 选择问题类型 */
      radio: 1,
      repair_type: [
        {
          index: 0,
          type: "底单申请",
          action: "申请",
          id: 1
        },
        {
          index: 1,
          type: "快递异常",
          action: "提问",
          id: 2
        },
        {
          index: 2,
          type: "其他问题",
          action: "提问",
          id: 3
        }
      ],
      imageArr: [],
      clickCopyAera: false,
      problemObj: {
        waybillNo: "",
        typeName: "底单申请",
        username: "",
        mobile: "",
        title: "底单申请",
        typeId: "1"
      },
      screenWidth: document.body.clientWidth
    };
  },
  created() {
    this.getCommList();
    this.getUserName();
  },
  mounted() {
    window.addEventListener("resize", this.handleWidth);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleWidth);
  },
  methods: {
    handleWidth() {
      this.screenWidth = document.body.clientWidth;
    },
    getUserName() {
      sipUser().then(res => {
        if (res.success) {
          // this.problemObj.username = res.data.username;
          this.problemObj.mobile = res.data.mobile;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getCommList() {
      commList({ pageNum: 1, pageSize: 20 }).then(res => {
        if (res.success) {
          res.data.list &&
            res.data.list.forEach((item, index) => {
              item.index = index + 1;
            });
          this.problemList = res.data.list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleShowInfo(item, index) {
      if (this.activeInfo === index) {
        this.activeInfo = "";
      } else {
        this.activeInfo = index;
      }
    },

    selectRepairType(e) {
      this.submitInputPlaceholder = commonDescObj[this.radio];
      this.problemObj.typeId = e.target.value;
      this.problemObj.typeName = this.repair_type[e.target.value - 1].type;
      this.problemObj.title = this.problemObj.typeName;
      //清空
      this.submitInputContent = "";
      this.imageArr = [];
    },
    submitRepair() {
      const { problemObj, imageArr } = this;
      let files = [];
      imageArr.forEach(item => {
        files.push(item.url);
      });
      problemObj.content = JSON.stringify([
        {
          time: new Date().getTime(),
          text: this.submitInputContent,
          files: files,
          admin: false
        }
      ]);
      this.loading = true;
      problemInsert(problemObj).then(res => {
        if (res.success) {
          this.loading = false;
          this.currentTab += 1;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handlePaste(event) {
      if (this.imageArr.length >= 5) {
        return this.$message.error("最多只能上传5张图片");
      }
      const items = event.clipboardData && event.clipboardData.items;
      var file = null;
      if (items && items.length) {
        // 搜索剪切板items
        for (var i = 0; i < items.length; i++) {
          if (items[i].type.indexOf("image") !== -1) {
            file = items[i].getAsFile();
            this.uploadProcessing(file);
          }
        }
      } else {
        this.$message.error("当前浏览器不支持");
        return;
      }
      if (!file) {
        this.$message.error("粘贴内容非图片");
        return;
      }
    },
    handleChange(fileData) {
      this.uploadProcessing(fileData.file);
    },
    uploadProcessing(file) {
      filePreUpload()
        .then(response => {
          const data = response.data;
          const url = data.url;
          const prefix = data.key;
          delete data.url;
          delete data.key;
          const array = file.name.replace(" ", "").split("."); // 把文件名数组化
          let suffix = ""; // 后缀
          if (array.length > 1) {
            suffix = `.${array.pop().toLowerCase()}`;
          }
          const formData = new FormData();
          Object.keys(data).forEach(it => {
            formData.append(it, data[it]);
          });
          const key = `${prefix}/${uuid()}${suffix}`; // 图片访问路径

          formData.append("key", key);
          formData.append("file", file);
          uploadOSS(url, formData)
            .then(response => {
              // 上传成功，设置图片访问路径
              let obj = {
                url: "http://cache.wodh.cn/" + key,
                show: false
              };
              this.imageArr.push(obj);
              this.$message.success("上传成功");
              this.clickCopyAera = false;
              // 粘贴来的图片没有这个事件
              // return fileData.onSuccess(); // 上传成功了
            })
            .catch(error => {
              this.$message.error("上传异常." + error);
              // return fileData.onError(); // 拒绝上传操作
            });
        })
        .catch(error => {
          this.$message.error("授权异常." + error);
          // return fileData.onError(); // 拒绝上传操作
        });
    },
    nextStep() {
      const { problemObj, radio, imageArr } = this;
      const { mobile, title, waybillNo, username } = problemObj;
      if ([1, 2].includes(radio)) {
        if (!waybillNo) {
          return this.$message.error("请输入快递单号");
        }
        if (radio === 1 && !imageArr.length) {
          return this.$message.error("请上传店铺申诉页面的截图证明");
        }
        if (radio === 1 && !username) {
          return this.$message.error("请填写微信号");
        }
      }
      if (!mobile || !title || !this.submitInputContent) {
        return this.$message.error("请输入完整的内容");
      }
      if (!/^1[3456789]\d{9}$/.test(mobile)) {
        return this.$message.error("请输入正确的手机号");
      }
      if (this.currentTab < 2) {
        this.currentTab += 1;
      }
    },
    prevStep() {
      if (this.currentTab > 0) {
        this.currentTab -= 1;
      }
    },
    submitMore() {
      this.currentTab = 0;
      this.problemObj.typeName = "底单申请";
      this.problemObj.title = "底单申请";
      this.problemObj.typeId = "1";
      this.submitInputContent = "";
      this.imageArr = [];
      this.radio = 1;
    },
    handleRemove(item) {
      this.imageArr = this.imageArr.filter(it => it.url !== item.url);
    },
    handleMouseEnter(item) {
      item.show = true;
    },
    handleMouseLeave(item) {
      item.show = false;
    }
  }
};
</script>

<style lang="less" scoped>
.page_wrapper {
  display: flex;
}
.order_submit_wrapper {
  flex: 1;
  padding: 88px 0;
  height: 832px;
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  .steps {
    max-width: 750px;
    margin: 0 auto 56px;
  }

  .action_wrapper {
    margin: 0 auto;
    .resize_none {
      resize: none;
    }
    .action_item {
      margin-bottom: 24px;
      display: flex;
      align-items: flex-start;
      .label {
        width: 80px;
        margin-right: 12px;
        text-align: right;
      }
      .input {
        width: 400px;
      }
    }
    .submit_image {
      width: 104px;
      height: 104px;
      cursor: pointer;
      margin-right: 16px;
      border: 1px solid #eee;
      border-radius: 4px;
    }
    .edit_tip {
      cursor: pointer;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 104px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      color: #fff;
      background-color: #595959;
    }
    .paste_box {
      width: 104px;
      height: 104px;
      margin-bottom: 12px;
      border: 1px dashed rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: rgba(0, 0, 0, 0.25);
      cursor: pointer;
    }
    .active {
      color: #1890ff;
    }

    .btn {
      margin-left: 92px;
      .prve_step {
        margin-right: 24px;
      }
    }
    .last_step_btn {
      display: flex;
      justify-content: center;
    }
  }
}
.problem_wrapper {
  background-color: #fff;
  padding-bottom: 18px;
  .problem_title {
    font-size: 14px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    padding: 16px 24px;
    border-bottom: 1px solid #f0f0f0;
  }
  .common_problem {
    padding: 0 24px;

    .common_problem_item {
      border-bottom: 1px solid #f0f0f0;
      padding: 16px 0;
      .item_question {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        .circle {
          width: 8px;
          height: 8px;
          background: rgba(0, 0, 0, 0.15);
          border-radius: 50%;
          margin-right: 12px;
          &_active {
            background: #1890ff;
          }
        }

        .item_title {
          cursor: pointer;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.85);
        }
        .active {
          color: #1890ff;
        }
      }
      .item_answer {
        margin-left: 20px;
        word-break: break-all;
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }

  .detailed_content {
    .goback {
      text-align: right;
      font-size: 20px;
      cursor: pointer;
    }
    .detailed_title {
      font-weight: 600;
      margin-bottom: 12px;
      font-size: 16px;
    }
  }
}
.right1 {
  width: 506px;
  margin-left: 24px;
}
.right2 {
  margin-top: 24px;
}
</style>
